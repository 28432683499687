import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pluralized from '../../common/helpers/pluralized';

export class CountdownTimer extends Component {
    static propTypes = {
        initTime: PropTypes.number.isRequired,
        timeIsOverHandler: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.initTime = new Date(Date.now() + props.initTime * 1000); // timestamp in seconds

        const { minutes, seconds } = this.getTimeComponents();

        this.state = {
            components: {
                min: { value: minutes, label: pluralized(minutes, 'минут:минута:минуты') },
                sec: { value: seconds, label: pluralized(seconds, 'секунд:секунда:секунды') },
            },
        };
    }

    componentDidMount() {
        this.start();
    }

    componentWillUnmount() {
        this.clear();
    }

    start = () => {
        this.timer = setInterval(() => this.setTime(), 1000);
    };

    getTimeComponents = () => {
        const now = Date.now();
        const interval = (this.initTime - now) / 1000;

        if (interval < 0) {
            return {
                minutes: 0,
                seconds: 0,
                interval,
            };
        }
        return {
            minutes: Math.floor(Math.round(interval) / 60),
            seconds: Math.round(interval) % 60,
            interval,
        };
    };

    setTime = () => {
        const { minutes, seconds, interval } = this.getTimeComponents();
        if (interval < 0) {
            this.clear();
        } else {
            this.setState({
                components: {
                    min: { value: minutes, label: pluralized(minutes, 'минут:минута:минуты') },
                    sec: { value: seconds, label: pluralized(seconds, 'секунд:секунда:секунды') },
                },
            });
        }
    };

    clear = () => {
        clearTimeout(this.timer);
        this.props.timeIsOverHandler();
    };

    getTimer = () => {
        const { components } = this.state;
        return (
            <div className="countdown-timer">
                {Object.keys(components).map(key => (
                    <div className="countdown-timer-separator" key={ components[key].label }>
                        <span>{`${components[key].value} ${components[key].label}`}</span>
                    </div>
                ))}
            </div>

        );
    };

    render = () => (
        this.getTimer()
    )
}
