const loginDictionary = {
    ru: {
        login: 'Логин',
        password: 'Пароль',
        toComeIn: 'Войти',
        forgotYourPassword: 'Забыли пароль?',
        close: 'Закрыть',
        passwordRecovery: 'Восстановление пароля',
        otherPeoplePC: 'Чужой компьютер',
        enterCode: 'Введите код, отправленный вам по SMS',
        codeFromSMS: 'Код из SMS',
        send: 'Отправить',
        cantSignIn: 'Не получается войти?',
    },
    tg: {
        login: 'Даромадан',
        password: 'Гузарвожа',
        toComeIn: 'Даромадан',
        forgotYourPassword: 'Калидвожаатонро фаромӯш кардед?',
        close: 'Пӯшед',
        passwordRecovery: 'Барқароркунии парол',
        otherPeoplePC: 'одамони дигар компютер',
        enterCode: 'Рамзи ба шумо фиристодаи SMS-ро ворид кунед',
        codeFromSMS: 'Рамзи SMS',
        send: 'Паём фиристед',
        cantSignIn: 'Вуруд карда наметавонед?',
    },
    uz: {
        login: 'Kirish',
        password: 'Parol',
        toComeIn: 'Kirish',
        forgotYourPassword: 'Parolni unutdingizmi?',
        close: 'Yoping',
        passwordRecovery: 'Parolni tiklash',
        otherPeoplePC: 'boshqa odamlar kompyuter',
        enterCode: 'Sizga SMS orqali yuborilgan kodni kiriting',
        codeFromSMS: 'SMS-kod',
        send: 'Xabar yuboring',
        cantSignIn: 'Kirish mumkin emasmi?',
    },
};

export default loginDictionary;
