import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper } from 'intdev-ui';
import { isBrowserWorseThanIE11 } from '@/common/helpers/helpers';
import loginDictionary from '@/login/constants/loginDictionary';
import { LoginForm } from './LoginForm';
import { CodeForm } from './CodeForm';
import {
    FORGOT_PASSWORD_HTML,
    PROBLEMS_HTML,
    CODE_FORM,
    LOGIN_FORM,
} from '../constants';
import { getParameterByName } from '../helpers';


export class LoginPage extends Component {
    static propTypes = {
        title: PropTypes.node.isRequired,
        greeting: PropTypes.node.isRequired,
        serviceInfo: PropTypes.node.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        submitLoginFormUrl: PropTypes.string.isRequired,
        strictAuthType: PropTypes.string.isRequired,
        raduisEnabled: PropTypes.bool.isRequired,
        nextUrl: PropTypes.string,
        forgotPasswordHtml: PropTypes.string,
        problemsHtml: PropTypes.string,
        captchaKey: PropTypes.string,
        locale: PropTypes.string,
    };

    static defaultProps = {
        nextUrl: null,
        forgotPasswordHtml: FORGOT_PASSWORD_HTML,
        problemsHtml: PROBLEMS_HTML,
        captchaKey: '',
        locale: 'ru',
    };

    state = {
        form: LOGIN_FORM,
        submit2faCodeUrl: '',
        sendNewCodeUrl: '',
        waitTimer: 0,
    };

    get nextUrl() {
        return this.props.nextUrl || getParameterByName('next') || '/';
    }

    showCodeForm = (submit2faCodeUrl, sendNewCodeUrl, waitTimer) => {
        this.setState({
            form: CODE_FORM, submit2faCodeUrl, sendNewCodeUrl, waitTimer,
        });
    };

    render() {
        const {
            isAuthenticated, title, greeting, submitLoginFormUrl, strictAuthType,
            raduisEnabled, forgotPasswordHtml, problemsHtml, captchaKey, serviceInfo,
            locale,
        } = this.props;

        const {
            form, submit2faCodeUrl, sendNewCodeUrl, waitTimer,
        } = this.state;

        if (isAuthenticated) {
            return null;
        }

        const dictionary = loginDictionary[locale] || loginDictionary.ru;

        let formComponent;
        switch (form) {
            case LOGIN_FORM: {
                formComponent = (
                    <LoginForm
                        title={ title }
                        greeting={ greeting }
                        next={ this.nextUrl }
                        showCodeForm={ this.showCodeForm }
                        submitLoginFormUrl={ submitLoginFormUrl }
                        strictAuthType={ strictAuthType || null }
                        raduisEnabled={ raduisEnabled }
                        forgotPasswordHtml={ forgotPasswordHtml }
                        captchaKey={ captchaKey }
                        loginDictionary={ dictionary }
                    />
                );
                break;
            }
            case CODE_FORM: {
                formComponent = (
                    <CodeForm
                        submitUrl={ submit2faCodeUrl }
                        sendNewCodeUrl={ sendNewCodeUrl }
                        next={ this.nextUrl }
                        problemsHtml={ problemsHtml }
                        waitTimer={ waitTimer }
                        loginDictionary={ dictionary }
                    />
                );
                break;
            }
            default:
                formComponent = null;
        }

        let browserWarning = null;
        if (isBrowserWorseThanIE11()) {
            browserWarning = (
                <div className="browser-warning-wrapper">
                    <div className="browser-warning">
                        Мы не поддерживаем работу в браузере Internet Explorer,
                        пожалуйста, используй Chrome, Firefox, Edge, Safari или другой современный браузер
                    </div>
                </div>
            );
        }

        return (
            <>
                { browserWarning }
                <div className="login-page-container">
                    <div id="login">
                        <Paper className="login-paper">
                            { formComponent }
                        </Paper>
                    </div>
                    {
                        serviceInfo
                        && (
                            <div id="intranet-info">
                                {/* eslint-disable react/no-danger */}
                                <div
                                    className="intranet-info-text"
                                    dangerouslySetInnerHTML={ { __html: serviceInfo } }
                                />
                                {/* eslint-enable react/no-danger */}
                            </div>
                        )
                    }
                </div>
            </>
        );
    }
}
