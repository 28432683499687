export const styles = {
    errorText: {
        width: 250,
    },
    authTypeContainer: {
        marginBottom: '20px',
    },
    authTypeTab: {
        padding: '12px 24px 12px 0px',
    },
};
