import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    A, Button, Checkbox, Dialog, Spinner, Tabs, TextField,
} from 'intdev-ui';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { FORMDATA } from '@/common/constants/ajaxBodyTypes';
import { objSnakeFromCamel } from '@/common/helpers/objCamelFromSnake';
import { commonMedia } from '@/common/media';
import {
    AUTH_TYPES,
    AUTH_TYPE_TABS,
    ALLOWED_AUTH_TYPES,
} from '../constants';
import { styles } from '../styles';

export class LoginForm extends Component {
    static propTypes = {
        title: PropTypes.node.isRequired,
        greeting: PropTypes.node.isRequired,
        next: PropTypes.string.isRequired,
        showCodeForm: PropTypes.func.isRequired,
        submitLoginFormUrl: PropTypes.string.isRequired,
        strictAuthType: PropTypes.oneOf(ALLOWED_AUTH_TYPES),
        raduisEnabled: PropTypes.bool.isRequired,
        forgotPasswordHtml: PropTypes.string.isRequired,
        captchaKey: PropTypes.string,
        loginDictionary: PropTypes.instanceOf(Object),
    };

    static defaultProps = {
        strictAuthType: null,
        captchaKey: '',
        loginDictionary: {},
    };

    state = {
        username: '',
        password: '',
        authTypeIndex: 0,
        validationErrors: {},
        isOpenForgotPasswordPopover: false,
        recaptcha: null,
        isAnotherComputer: false,
    };

    componentDidMount() {
        const { captchaKey } = this.props;
        if (captchaKey) loadReCaptcha(captchaKey);
    }


    get selectedAuthType() {
        const { strictAuthType } = this.props;
        const idx = strictAuthType ? ALLOWED_AUTH_TYPES.indexOf(strictAuthType) : this.state.authTypeIndex;
        if (!AUTH_TYPES[idx]) {
            throw new Error('incorrect auth type');
        }
        return AUTH_TYPES[idx];
    }

    handleClickForgotPasswordLink = () => {
        this.setState(state => ({
            isOpenForgotPasswordPopover: !state.isOpenForgotPasswordPopover,
        }));
    };

    handleRequestCloseForgotPasswordPopover = () => {
        this.setState({ isOpenForgotPasswordPopover: false });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isFetching: true,
        });
        let body = {
            username: this.state.username,
            password: this.state.password,
            authType: this.selectedAuthType.value,
            next: this.props.next,
            recaptcha: this.state.recaptcha,
            isAnotherComputer: this.state.isAnotherComputer,
        };

        body = objSnakeFromCamel(body);

        callApi(`${this.props.submitLoginFormUrl}${window.location.search}`, 'POST', body, FORMDATA)
            .then((data) => {
                if (data.errors) {
                    this.setState({
                        validationErrors: data.errors,
                        isFetching: false,
                    });
                    this.recaptcha.execute();
                } else if (data.next) {
                    window.location.href = data.next;
                } else if (!!data.submit2faCodeUrl && !!data.sendNewCodeUrl) {
                    this.props.showCodeForm(data.submit2faCodeUrl, data.sendNewCodeUrl, data.waitTimer);
                } else {
                    window.location.href = '/';
                }
            }).catch((error) => {
                this.setState({
                    validationErrors: {
                        __all__: [error.errors.code[0] || `При попытке авторизоваться произошла ошибка (${error.status}: ${error.statusText})`],
                    },
                    isFetching: false,
                });
            });
    };

    handleChangeUsername = (e) => {
        let username = e.target.value;
        const i = username.indexOf('@');

        if (i !== -1) {
            username = username.slice(0, i);
        }

        this.setState({ username });
    };

    handleChangeAuthTypeIndex = id => this.setState({ authTypeIndex: id });

    captchaVerifyCallback = (token) => {
        this.setState({
            recaptcha: token,
        });
    };

    handleChangeIsAnotherComputer = (e) => {
        this.setState({
            isAnotherComputer: e.target.checked,
        });
    };

    render() {
        const {
            validationErrors, authTypeIndex,
        } = this.state;
        const {
            title, greeting, strictAuthType, raduisEnabled, captchaKey,
            loginDictionary,
        } = this.props;
        const authType = this.selectedAuthType;

        return (
            <form method="POST" action="" onSubmit={ this.handleSubmit }>
                <div className="login-form-container">
                    {/* eslint-disable react/no-danger */}
                    <div
                        className="header"
                        dangerouslySetInnerHTML={ { __html: title } }
                    />
                    { greeting && (
                        <div
                            className="help-container"
                            dangerouslySetInnerHTML={ { __html: greeting } }
                        />
                    )}
                    { !strictAuthType && raduisEnabled && (
                        <Tabs
                            tabs={ AUTH_TYPE_TABS }
                            containerStyle={ styles.authTypeContainer }
                            tabStyle={ styles.authTypeTab }
                            curTabIndex={ authTypeIndex }
                            onTabClick={ this.handleChangeAuthTypeIndex }
                        />
                    )}
                    { authType.helpMessage && (
                        <div className="help-container">
                            { authType.helpMessage }
                        </div>
                    )}
                    <div className="help-container">
                        <TextField
                            id="login-form__username-field"
                            name="username"
                            hintText={ loginDictionary.login }
                            required
                            value={ this.state.username }
                            onChange={ this.handleChangeUsername }
                            validationState={ validationErrors.username ? 'error' : undefined }
                            validationText={ !!validationErrors.username && validationErrors.username.map(e => (
                                <span key={ e }>
                                    {e}
                                    <br />
                                </span>
                            )) }
                            disabled={ this.state.isFetching }
                            autoFocus
                            fullWidth
                            hintStyle={ commonMedia.textFieldHintStyle }
                            inputStyle={ commonMedia.fontSizeBody }
                            inputWrapperStyle={ commonMedia.textFieldInputWrapperStyle }
                        />
                    </div>
                    <div className="help-container">
                        <TextField
                            id="login-form__password-field"
                            name="password"
                            hintText={ loginDictionary.password }
                            type="password"
                            required
                            value={ this.state.password }
                            onChange={ e => this.setState({ password: e.target.value }) }
                            validationState={ validationErrors.password ? 'error' : undefined }
                            validationText={ !!validationErrors.password && validationErrors.password.map(e => (
                                <span key={ e }>
                                    {e}
                                    <br />
                                </span>
                            )) }
                            disabled={ this.state.isFetching }
                            fullWidth
                            hintStyle={ commonMedia.textFieldHintStyle }
                            inputStyle={ commonMedia.fontSizeBody }
                            inputWrapperStyle={ commonMedia.textFieldInputWrapperStyle }
                        />
                    </div>
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    { !!this.state.validationErrors.__all__ && (
                        <div>
                            {/* eslint-disable-next-line no-underscore-dangle */}
                            {this.state.validationErrors.__all__.map(e => (
                                <div className="error" key={ e }>
                                    {e}
                                </div>
                            ))}
                        </div>
                    )}
                    { captchaKey && (
                        <div className="help-container">
                            <ReCaptcha
                                ref={ (ref) => { this.recaptcha = ref; } }
                                action="login"
                                sitekey={ captchaKey }
                                verifyCallback={ this.captchaVerifyCallback }
                            />
                            { !!validationErrors.recaptcha && (
                                <div className="error">{ validationErrors.recaptcha[0] }</div>
                            )}
                        </div>
                    )}
                </div>

                <div className="footer">
                    <div className="submit-button-wrapper">
                        <Button
                            primary
                            label={ loginDictionary.toComeIn }
                            type="submit"
                            disabled={ this.state.isFetching }
                            { ...commonMedia.buttonStyles }
                        />
                        {this.state.isFetching && <Spinner style={ { marginLeft: 10 } } />}
                    </div>
                    <div className="footer_code">
                        { this.state.password ? (
                            <>
                                <Checkbox
                                    checked={ this.state.isAnotherComputer }
                                    onChange={ this.handleChangeIsAnotherComputer }
                                    style={ { cursor: 'pointer' } }
                                />
                                <span>{ loginDictionary.otherPeoplePC }</span>
                            </>
                        ) : (
                            <A
                                className="send-code-link"
                                onClick={ this.handleClickForgotPasswordLink }
                            >
                                { loginDictionary.forgotYourPassword }
                            </A>
                        )}
                    </div>
                </div>

                <Dialog
                    title={ loginDictionary.passwordRecovery }
                    actions={ [
                        <Button
                            label={ loginDictionary.close }
                            onClick={ this.handleClickForgotPasswordLink }
                            { ...commonMedia.buttonStyles }
                        />,
                    ] }
                    open={ this.state.isOpenForgotPasswordPopover }
                    onRequestClose={ this.handleRequestCloseForgotPasswordPopover }
                >
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={ { __html: this.props.forgotPasswordHtml } } />
                </Dialog>
            </form>
        );
    }
}
