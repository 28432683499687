import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    A, Button, Spinner, TextField, Dialog,
} from 'intdev-ui';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { FORMDATA } from '@/common/constants/ajaxBodyTypes';
import { commonMedia } from '@/common/media';
import { CountdownTimer } from './CountdownTimer';
import { styles } from '../styles';

export class CodeForm extends Component {
    static propTypes = {
        submitUrl: PropTypes.string.isRequired,
        sendNewCodeUrl: PropTypes.string.isRequired,
        next: PropTypes.string.isRequired,
        problemsHtml: PropTypes.string.isRequired,
        waitTimer: PropTypes.number.isRequired,
        loginDictionary: PropTypes.instanceOf(Object),
    };

    static defaultProps = {
        loginDictionary: {},
    };

    state = {
        code: '',
        validationErrors: {},
        isFetching: false,
        isOpenForgotPasswordPopover: false,
        timeIsOver: false,
    };

    handleClickTroubleWithCodePopoverLink = () => {
        this.setState({ isOpenForgotPasswordPopover: true });
    };

    handleCloseTroubleWithCodePopover = () => {
        this.setState({ isOpenForgotPasswordPopover: false });
    };

    handleClickSendNewCode = (e) => {
        e.preventDefault();
        this.setState({
            isFetching: true,
        });
        callApi(this.props.sendNewCodeUrl, 'POST', {}, FORMDATA)
            .then((data) => {
                this.setState({
                    isFetching: false,
                    waitTimer: data.waitTimer,
                    timeIsOver: false,
                });
            }).catch((response) => {
                this.setState({
                    isFetching: false,
                    validationErrors: response.errors || {},
                });
            });
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            isFetching: true,
        });
        callApi(this.props.submitUrl, 'POST', { code: this.state.code, next: this.props.next }, FORMDATA)
            .then((data) => {
                if (data.next) {
                    window.location.href = data.next;
                } else {
                    window.location.href = '/';
                }
            }).catch((response) => {
                this.setState({
                    isFetching: false,
                    validationErrors: response.errors || {},
                });
            });
    };

    handleChangeCode = (e) => {
        this.setState({ code: e.target.value });
    };

    timeIsOverHandler = () => {
        this.setState({
            timeIsOver: true,
        });
    };

    render() {
        const { validationErrors, timeIsOver, waitTimer } = this.state;
        const { loginDictionary } = this.props;
        const waitingTimer = this.props.waitTimer || waitTimer;

        const countdownTimer = (
            <div className="send-code-timer">
                <CountdownTimer timeIsOverHandler={ this.timeIsOverHandler } initTime={ waitingTimer } />
            </div>
        );

        return (
            <form method="POST" action="" onSubmit={ this.handleSubmit } autoComplete="off">
                <div className="form">
                    <div className="header">{ loginDictionary.enterCode }</div>
                    <div className="header-field-wrapper">
                        <TextField
                            id="login-form__username-field"
                            name="code"
                            hintText={ loginDictionary.codeFromSMS }
                            fullWidth
                            required
                            value={ this.state.code }
                            onChange={ this.handleChangeCode }
                            validationState={ validationErrors.code ? 'error' : undefined }
                            validationText={ !!validationErrors.code && validationErrors.code.map(e => (
                                e
                            )) }
                            validationStyle={ styles.errorText }
                            disabled={ this.state.isFetching }
                            maxLength={ 6 }
                            autoFocus
                            hintStyle={ commonMedia.textFieldHintStyle }
                            inputStyle={ commonMedia.fontSizeBody }
                            inputWrapperStyle={ commonMedia.textFieldInputWrapperStyle }
                        />
                        <div className="help-text">
                            <A
                                onClick={ this.handleClickTroubleWithCodePopoverLink }
                                style={ { fontSize: 12, cursor: 'pointer' } }
                            >
                                { loginDictionary.cantSignIn }
                            </A>
                        </div>
                    </div>
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    { !!this.state.validationErrors.__all__ && (
                        <div className="errors-wrapper">
                            {/* eslint-disable-next-line no-underscore-dangle */}
                            { this.state.validationErrors.__all__.map(
                                e => <div className="error" key={ e }>{e}</div>,
                            ) }
                        </div>
                    ) }
                </div>

                <div className="footer">
                    <div className="submit-button-wrapper">
                        <Button
                            primary
                            label={ loginDictionary.send }
                            type="submit"
                            disabled={ this.state.isFetching }
                            { ...commonMedia.buttonStyles }
                        />
                        {this.state.isFetching && <Spinner style={ { marginLeft: 10 } } />}
                    </div>
                    <div className="send-code">
                        {
                            timeIsOver
                                ? <A className="send-code-link" onClick={ this.handleClickSendNewCode }>Выслать новый код</A>
                                : countdownTimer
                        }
                    </div>
                </div>
                <Dialog
                    title={ loginDictionary.cantSignIn }
                    actions={ [
                        <Button
                            label={ loginDictionary.close }
                            onClick={ this.handleCloseTroubleWithCodePopover }
                            { ...commonMedia.buttonStyles }
                        />,
                    ] }
                    open={ this.state.isOpenForgotPasswordPopover }
                    onRequestClose={ this.handleCloseTroubleWithCodePopover }
                >
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={ { __html: this.props.problemsHtml } } />
                </Dialog>
            </form>
        );
    }
}
