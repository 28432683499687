export const FORGOT_PASSWORD_HTML = '<p>Если пароль не подходит, возможно у него закончился срок действия. Попробуй сменить его</p>';

export const PROBLEMS_HTML = '<p>Если возникли сложности с двухфакторной авторизацией, можно обратиться в службу поддержки.</p>';

export const LOGIN_FORM = 'LOGIN_FORM';
export const CODE_FORM = 'CODE_FORM';

export const AUTH_TYPES = [
    {
        value: 'domain',
        label: 'Доменный пароль',
    },
    {
        value: 'radius',
        label: 'Токен',
        helpMessage: 'Логин для аутентификации по токену может отличаться от доменного',
    },
];

export const ALLOWED_AUTH_TYPES = AUTH_TYPES.map(el => el.value);

export const AUTH_TYPE_TABS = AUTH_TYPES.map(el => el.label);
