import React from 'react';
import { render } from '../common/helpers/render';
import { LoginPage } from './components/LoginPage';
import './styles/styles.css';


const loginContainer = document.getElementById('login-container');
if (loginContainer) {
    const loginForm = (
        <LoginPage
            title={ document.getElementById('login_service-title').innerHTML }
            greeting={ document.getElementById('login_service-greeting').innerHTML }
            serviceInfo={ document.getElementById('login_service-info').innerHTML }
            strictAuthType={ document.getElementById('login_strict-auth-type').innerHTML }
            nextUrl={ document.getElementById('login_next-url').innerHTML }
            isAuthenticated={ window.loginForm.isAuthenticated }
            submitLoginFormUrl={ loginContainer.dataset.submitLoginFormUrl }
            raduisEnabled={ loginContainer.dataset.radiusEnabled === 'true' }
            forgotPasswordHtml={ loginContainer.dataset.forgotPasswordHtml }
            problemsHtml={ loginContainer.dataset.problemsHtml }
            captchaKey={ loginContainer.dataset.captchaKey }
            locale={ loginContainer.dataset.locale }
        />
    );

    render(loginForm, loginContainer);

    // Hot Module Replacement API
    if (module.hot) {
        module.hot.accept('./components/LoginForm', () => {
            render(loginForm, loginContainer);
        });
    }
}
